import React from "react"

function Twitter() {
  return (
    <svg className="icon icon__twitter" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.7 33" width="40.7" height="33">
      <title>Follow Robert Idol on Twitter</title>
      <path
        d="M0,29.2A23.47,23.47,0,0,0,12.8,33C28.3,33,37.1,19.9,36.5,8.2a19.07,19.07,0,0,0,4.2-4.3,15.51,15.51,0,0,1-4.8,1.3A8.9,8.9,0,0,0,39.6.6a14.2,14.2,0,0,1-5.3,2,8.37,8.37,0,0,0-14.2,7.7A23.71,23.71,0,0,1,2.9,1.6,8.28,8.28,0,0,0,5.5,12.7a8,8,0,0,1-3.8-1A8.34,8.34,0,0,0,8.4,20a8.11,8.11,0,0,1-3.8.1,8.42,8.42,0,0,0,7.8,5.8A17.48,17.48,0,0,1,0,29.2Z"
        fill="currentColor"
      />
    </svg>
  )
}

export { Twitter }
