import React from "react"

function Pennant(props) {
  const { headline } = props
  return (
    <div className="pennant">
      <div className="pennant__wrapper">
        <h3 className="pennant__headline">{headline}</h3>
      </div>
    </div>
  )
}

export { Pennant }
