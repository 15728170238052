import React from "react"
import { Link } from "gatsby"

function generateMenuWithLinks({ slug, menuItems, ulClassName, liClassName, aClassName, aClassNameActive }) {
  function setActiveNavLink(menuItem) {
    return slug === menuItem.slug ? aClassNameActive : ""
  }

  return (
    <ul className={ulClassName}>
      {menuItems.map(menuItem => (
        <li key={menuItem.title} className={liClassName}>
          <Link className={`${aClassName} ${setActiveNavLink(menuItem)}`} to={`/${menuItem.slug}`}>
            {menuItem.title}
          </Link>
        </li>
      ))}
    </ul>
  )
}

export { generateMenuWithLinks }
