import React from "react"

function Header(props) {
  const { children, headerHeadline } = props
  return (
    <div className="header">
      <div className="header__wrapper">
        <div className="header__container">
          <h2 className="header__headline">{headerHeadline}</h2>
        </div>
        {children}
      </div>
    </div>
  )
}

export { Header }
