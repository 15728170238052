import React, { useState } from "react"
import { Link } from "gatsby"

// Utility Functions
import { generateMenuWithLinks } from "../utils/generateMenuWithLinks"

// Custom Components
import { Logo } from "./Logo"
import { Caret } from "./Caret"

function Nav(props) {
  const [isNavMenuOpen, setNavMenu] = useState(false)
  const { menuItemsSorted, slug } = props

  function handleNav() {
    setNavMenu(!isNavMenuOpen)
  }

  return (
    <div className={isNavMenuOpen ? "nav nav__open" : "nav"}>
      <nav className="nav__wrapper">
        <Link className="nav__logo-container" to="/">
          <h1>
            <Logo location="nav" />
          </h1>
        </Link>
        <div className="nav__container">
          <button className="nav__button" onClick={() => handleNav()} type="button">
            <span className="nav__button-text">Menu</span>
            <Caret />
          </button>
          {generateMenuWithLinks({
            slug,
            menuItems: menuItemsSorted,
            ulClassName: "nav__menu",
            liClassName: "nav__menu-item",
            aClassName: "nav__menu-item-link",
            aClassNameActive: "nav__menu-item-link--active",
          })}
        </div>
      </nav>
    </div>
  )
}

export { Nav }
