import React from "react"
import { Link } from "gatsby"

// Utility Functions
import { generateMenuWithLinks } from "../utils/generateMenuWithLinks"

// Custom Components
import { Logo } from "./Logo"
import { LinkedIn } from "./LinkedIn"
import { Twitter } from "./Twitter"

function Footer(props) {
  const { companyName, companyTwitter, companyLinkedIn, menuItemsSorted, slug } = props

  function hasSocialMediaLink(link) {
    if (link.length > 0) {
      return true
    }
    return false
  }
  return (
    <>
      <section>
        <footer className="footer">
          <div className="footer__wrapper">
            <Link className="footer__container" to="/">
              <Logo location="footer" />
            </Link>
            <div className="footer__container">
              {generateMenuWithLinks({
                slug,
                menuItems: menuItemsSorted,
                ulClassName: "footer__menu",
                liClassName: "footer__menu-item",
                aClassName: "footer__menu-item-link",
                aClassNameActive: "footer__menu-item-link--active",
              })}
            </div>
            <div className="footer__container">
              {hasSocialMediaLink(companyTwitter) && (
                <a className="footer__social-link" href={companyTwitter}>
                  <Twitter />
                </a>
              )}

              {hasSocialMediaLink(companyLinkedIn) && (
                <a className="footer__social-link" href={companyLinkedIn}>
                  <LinkedIn />
                </a>
              )}
            </div>
          </div>
        </footer>
      </section>
      <section>
        <footer className="copyright">
          <div className="copyright__wrapper">
            &copy; &nbsp;
            {new Date().getFullYear()}
            &nbsp; {companyName}
          </div>
        </footer>
      </section>
    </>
  )
}

export { Footer }
