import React from "react"

function LinkedIn() {
  return (
    <svg className="icon icon__linkedin" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.4 38.3" width="38.4" height="38.3">
      <title>Connect with Robert Idol on LinkedIn</title>
      <rect x="0.8" y="12.7" width="7.9" height="25.5" fill="currentColor" />
      <path d="M4.7,9.4A4.7,4.7,0,1,0,0,4.7,4.83,4.83,0,0,0,4.7,9.4Z" fill="currentColor" />
      <path
        d="M21.4,24.9c0-3.6,1.7-5.7,4.8-5.7,2.9,0,4.3,2.1,4.3,5.7V38.3h7.9V22.1c0-6.8-3.9-10.1-9.3-10.1a9,9,0,0,0-7.7,4.2V12.7H13.8V38.2h7.6Z"
        fill="currentColor"
      />
    </svg>
  )
}

export { LinkedIn }
