import React from "react"

function Quote(props) {
  const { quoteBody, quoteAuthor } = props
  return (
    <blockquote className="header__quote">
      {quoteBody}
      <div className="header__author">&mdash; {quoteAuthor}</div>
    </blockquote>
  )
}

export { Quote }
