import React, { useEffect, useRef } from "react"
import { graphql } from "gatsby"

// Utility Functions
import { handleMenuItems } from "../utils/handleMenuItems"
import { insertContentFromWYSIWYG } from "../utils/insertContentFromWYSIWYG"

// Custom components
import { Nav } from "../components/Nav"
import { Header } from "../components/Header"
import { Quote } from "../components/Quote"
import { Rollover } from "../components/Rollover"
import { Pennant } from "../components/Pennant"
import { Footer } from "../components/Footer"

// About page template
export default function TemplateAbout(props) {
  const {
    data: {
      wordpressPage: {
        slug,
        content,
        acf: {
          about_headline: aboutHeadline,
          about_quote_body: quoteBody,
          about_quote_author: quoteAuthor,
          about_bio_image: bioImage,
          about_bio_links: bioLinks,
          about_awards_headline: awardHeadline,
          about_award: aboutAward,
          about_publications_headline: publicationHeadline,
          about_publications_subheadline: publicationSubheadline,
          about_publication: aboutPublication,
          about_publication_with_image: aboutPublicationWithImage,
        },
      },
      wordpressAcfOptions: {
        options: {
          sitewide_company_information_company_name: companyName,
          sitewide_company_information_company_twitter: companyTwitter,
          sitewide_company_information_company_linkedin: companyLinkedIn,
        },
      },
      allWordpressPage: { edges },
    },
  } = props

  const WYSIWYGParent = useRef(null)
  const WYSIWYGChild = useRef(null)

  useEffect(() => {
    insertContentFromWYSIWYG({
      content,
      WYSIWYGParent,
      WYSIWYGChild,
    })
  }, [])

  function displayPublicationArticleName(publication) {
    if (publication !== "") {
      return <span className="press_article"> - {publication}</span>
    }
    return ""
  }

  return (
    <section className="white">
      <Nav slug={slug} menuItemsSorted={handleMenuItems({ items: edges })} />

      <Header headerHeadline={aboutHeadline}>
        <Quote quoteBody={quoteBody} quoteAuthor={quoteAuthor} />
      </Header>

      <div className="columns">
        <div className="columns__wrapper">
          <img className="columns__image" src={bioImage.localFile.childImageSharp.fluid.src} alt="Robert Idol" />
          <div className="columns__container" ref={WYSIWYGParent}>
            <div className="columns__banner" ref={WYSIWYGChild}>
              {bioLinks.map(link => (
                <Rollover key={link.about_bio_links_page_link} pageLink={link.about_bio_links_page_link} pageLinkCopy={link.about_bio_links_link_description} />
              ))}
            </div>
          </div>
        </div>
      </div>

      <Pennant headline={awardHeadline} />

      <div className="award">
        <div className="award__wrapper">
          <div className="award__container">
            {aboutAward.map(award => (
              <div key={award.about_award_image.localFile.childImageSharp.fluid.src} className="award__banner">
                <img className="award__image" src={award.about_award_image.localFile.childImageSharp.fluid.src} alt={award.about_award_description} />
              </div>
            ))}
          </div>
        </div>
      </div>

      <Pennant headline={publicationHeadline} />

      <div className="press">
        <div className="press__wrapper">
          <div className="press__container">
            <h4 className="press__headline">{publicationSubheadline}</h4>
            <ul className="press__menu">
              {aboutPublication.map(publication => (
                <li key={publication.about_publication_publication_name} className="press__menu-item">
                  {publication.about_publication_publication_name}
                </li>
              ))}
            </ul>
          </div>
          <div className="press__container press__container--alt">
            {aboutPublicationWithImage.map(publicationWithImage => (
              <div key={publicationWithImage.about_publication_with_image_image.localFile.childImageSharp.fluid.src} className="press__box">
                <img
                  className="press__image"
                  src={publicationWithImage.about_publication_with_image_image.localFile.childImageSharp.fluid.src}
                  alt={publicationWithImage.about_publication_title_of_publication}
                />
                <h5 className="press__title">{publicationWithImage.about_publication_title_of_publication}</h5>
                <h6 className="press__subtitle">
                  {publicationWithImage.about_publication_type_of_publication}
                  {displayPublicationArticleName(publicationWithImage.about_publication_article_name)}
                </h6>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Footer
        companyName={companyName}
        companyTwitter={companyTwitter}
        companyLinkedIn={companyLinkedIn}
        slug={slug}
        menuItemsSorted={handleMenuItems({ items: edges })}
      />
    </section>
  )
}

export const pageQuery = graphql`
  query {
    wordpressPage(title: { eq: "About" }) {
      slug
      content
      acf {
        about_headline
        about_quote_body
        about_quote_author
        about_bio_image {
          localFile {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
        about_bio_links {
          about_bio_links_page_link
          about_bio_links_link_description
        }
        about_awards_headline
        about_award {
          about_award_image {
            localFile {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
          about_award_description
        }
        about_publications_headline
        about_publications_subheadline
        about_publication {
          about_publication_publication_name
        }
        about_publication_with_image {
          about_publication_with_image_image {
            localFile {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
          about_publication_title_of_publication
          about_publication_type_of_publication
          about_publication_article_name
        }
      }
    }
    wordpressAcfOptions {
      options {
        sitewide_company_information_company_name
        sitewide_company_information_company_twitter
        sitewide_company_information_company_linkedin
      }
    }
    allWordpressPage(filter: { wordpress_parent: { eq: 0 } }) {
      edges {
        node {
          title
          menu_order
          slug
        }
      }
    }
  }
`
