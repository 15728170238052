import React from "react"

function Caret() {
  return (
    <svg className="icon icon__caret" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 289.04 492">
      <title>Caret Icon</title>
      <path
        d="M97.13,246.1,281.18,62a27,27,0,0,0,0-38.06L265.06,7.86a27,27,0,0,0-38.06,0L7.85,227a27.15,27.15,0,0,0,0,38.19L226.79,484.14a27,27,0,0,0,38.07,0L281,468A27,27,0,0,0,281,430Z"
        fill="currentColor"
      />
    </svg>
  )
}

export { Caret }
