import React from "react"

function Logo(props) {
  const { location } = props
  return (
    <svg
      className={`icon icon__logo icon__logo--${location}`}
      id="Isolation_Mode"
      data-name="Isolation Mode"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 184 118.3">
      <title>Robert Idol Logo</title>
      <path d="M8.9,70.7H.3V1.4H8.9Z" fill="currentColor" />
      <path
        d="M16.6,1.4H30.9c21.2,0,38.9,11.2,38.9,34.3,0,23.6-17.3,35-39.1,35H16.6Zm8.7,61.4h3c17.6,0,32.9-6.6,32.9-26.7S45.9,9.4,28.3,9.4h-3Z"
        fill="currentColor"
      />
      <path
        d="M148.1,36.1c0,20.6-16.3,36.1-36.3,36.1S75.5,56.7,75.5,36.1a36.3,36.3,0,0,1,72.6,0Zm-8.6,0c0-15.3-11.4-28-27.7-28s-27.7,12.8-27.7,28a27.7,27.7,0,1,0,55.4,0Z"
        fill="currentColor"
      />
      <path d="M162.9,62.8h21v7.9H154.2V1.4h8.6V62.8Z" fill="currentColor" />
      <rect y="78.4" width="184" height="8" fill="#f16022" />
      <path
        d="M.5,94.7H4.8c3.4,0,6.1.4,8.8,2.7a11.61,11.61,0,0,1,0,17c-2.7,2.4-5.4,2.8-8.9,2.8H.5ZM4.8,115c2.8,0,5.1-.4,7.1-2.3a8.7,8.7,0,0,0,2.8-6.6,9.06,9.06,0,0,0-2.9-6.8c-2.1-1.8-4.3-2.2-7-2.2H3V115Z"
        fill="currentColor"
      />
      <path d="M35.7,94.7H47.4V97H38.2v6.7h8.9V106H38.2v8.9h9.2v2.3H35.7Z" fill="currentColor" />
      <path
        d="M77.6,99.1a4.15,4.15,0,0,0-3.9-2.5,3.53,3.53,0,0,0-3.7,3.5c0,1.9,2,2.8,3.5,3.5l1.5.7c2.9,1.3,5.4,2.8,5.4,6.3a7.11,7.11,0,0,1-14.1,1.2l2.5-.7a4.64,4.64,0,0,0,4.5,4.2,4.55,4.55,0,0,0,4.6-4.4c0-2.6-2.1-3.5-4.2-4.5l-1.4-.6c-2.6-1.2-4.9-2.5-4.9-5.8,0-3.5,3-5.7,6.3-5.7a6.47,6.47,0,0,1,5.8,3.5Z"
        fill="currentColor"
      />
      <path d="M102.4,117.3H99.9V94.7h2.5Z" fill="currentColor" />
      <path
        d="M143.7,106c.1,3.1-.3,5.6-2.3,8a11.23,11.23,0,0,1-8.4,3.8,11.65,11.65,0,1,1,.2-23.3,11.89,11.89,0,0,1,9.6,4.9l-1.8,1.7a9.14,9.14,0,0,0-7.8-4.3,9.28,9.28,0,0,0-9.2,9.4,9.44,9.44,0,0,0,2.6,6.4,9.54,9.54,0,0,0,6.4,2.9c3.9,0,8.1-2.9,8.1-7.1h-6.7V106Z"
        fill="currentColor"
      />
      <path d="M163.1,93.8l17.6,18.4V94.7h2.5v23.6L165.6,99.9v17.4h-2.5Z" fill="currentColor" />
    </svg>
  )
}

export { Logo }
