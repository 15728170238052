// Sort through the filtered list to match the design concept
function sortMenuItems({ items }) {
  return items.sort((a, b) => {
    if (a.menu_order > b.menu_order) {
      return 1
    }
    return -1
  })
}

// Remove the home page from the menu list since it doesn't appear in the navigation menus
function removeHomePageFromMenu({ items }) {
  return sortMenuItems({ items: items.filter(item => item.title !== "Home") })
}

// Get all menu data from our graphQL query
function getMenuItemsFromNodes({ items }) {
  return removeHomePageFromMenu({ items: items.map(item => item.node) })
}

// Exposed function to take in graphQL query
function handleMenuItems({ items }) {
  return getMenuItemsFromNodes({ items })
}

export { handleMenuItems }
