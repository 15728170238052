import React from "react"
import { Link } from "gatsby"

function Rollover(props) {
  const { pageLink, pageLinkCopy } = props
  return (
    <div className="link-container">
      <Link className="link" to={`/${pageLink.match(/([^/]*)\/*$/)[1]}`}>
        {pageLinkCopy}
      </Link>
    </div>
  )
}

export { Rollover }
