function parseContentFromWYSIWYG({ content }) {
  const doc = new DOMParser()
  const html = doc.parseFromString(content, "text/html")
  return Array.from(html.body.children)
}

function insertContentFromWYSIWYG({ WYSIWYGParent, WYSIWYGChild, content }) {
  const contentArr = parseContentFromWYSIWYG({ content })
  const htmlObj = {
    names: contentArr.map(element => element.nodeName),
    copy: contentArr.map(element => element.textContent),
    nodes: [],
  }

  htmlObj.nodes = htmlObj.names.map(node => document.createElement(node))

  const { copy, nodes } = htmlObj

  nodes.forEach((node, idx) => {
    nodes[idx].textContent = copy[idx]
    WYSIWYGParent.current.insertBefore(nodes[idx], WYSIWYGChild.current)
  })
}

export { parseContentFromWYSIWYG, insertContentFromWYSIWYG }
